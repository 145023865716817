/*
 * 枚举
 */
import Vue from "vue";
import i18n from "../../tools/i18n";

const vueInstance = new Vue({
  i18n
});

const payChannelList = [{
  id: "RAZORPAY",
  name: "RAZORPAY"
}, ];

const getPayChannelList = (val) => {
  let re = "";
  payChannelList.forEach(function(obj) {
    if (obj.id == val) {
      re = obj.name;
      return;
    }
  });
  return re;
};
const orderStatus = [{
  id: 0,
  name: "初始化"
}, {
  id: 1,
  name: "处理中"
}, {
  id: 2,
  name: "交易成功"
}, {
  id: 3,
  name: "交易失败"
}, {
  id: 4,
  name: "回退"
}, {
  id: 5,
  name: "其他"
}];

const getOrderStatus = (val) => {
  let re = "";
  orderStatus.forEach(function(obj) {
    if (obj.id == val) {
      re = obj.name;
      return;
    }
  });
  return re;
};

let Enum = {
  payChannelList,
  getPayChannelList,
  getOrderStatus,
  orderStatus
};
export default Enum;
