import request from "@/utils/http/requset";

function getLengOrderList(data) {
  return request({
    url: "/manage/withdraw/search",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  getLengOrderList,
};
